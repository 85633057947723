import { Modal } from 'assets/components/modal';
import { Text } from 'assets/components/text';
import { TextField } from 'assets/components/text-field';
import { useForm } from 'assets/form';
import { TrashIcon } from 'assets/icons';
import { Form } from 'assets/layout/form/Form';
import { getText } from 'assets/localization/localization';
import { logError } from 'assets/logging/logger';
import { makeStyles } from 'assets/theme';
import _isEqual from 'lodash/isEqual';
import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { View } from 'react-native';
import { usePharmacySettingsState } from '../pharmacy-settings-store';
import { DepartmentItemRendererProps } from './DepartmentItemRenderer';

export const DepartmentModal: FunctionComponent<
  PropsWithChildren<any>
> = () => {
  const {
    editingDepartment,
    showModalDepartment,
    editingDepartmentId,
    setShowModalDepartment,
    createDepartment,
    updateDepartment,
    removeDepartment,
    getDepartmentsHours,
  } = usePharmacySettingsState();
  const styles = useStyles();
  const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);

  useEffect(() => {
    if (editingDepartmentId) {
      methods.reset({
        ...editingDepartment,
      });
    }
  }, [editingDepartmentId]);

  const methods = useForm<DepartmentItemRendererProps>({
    defaultValues: { name: '' },
    mode: 'onChange',
  });

  const handleSubmit = async () => {
    const formValue = methods.getValues();
    if (editingDepartmentId) {
      const updatedPharmacy = { ...editingDepartment, ...formValue };
      if (!_isEqual(updatedPharmacy, editingDepartment)) {
        await updateDepartment(updatedPharmacy);
      }
    } else {
      await createDepartment(formValue);
    }
    closeModal();
  };

  const handleDelete = async () => {
    if (editingDepartmentId) {
      await removeDepartment(editingDepartmentId)
        .then(() => getDepartmentsHours())
        .catch((error: Error) => logError(error));
    }
    setShowCloseConfirmation(false);
    closeModal();
  };

  const closeModal = () => {
    setShowModalDepartment(false);
    methods.reset({ name: '' });
  };

  return (
    <>
      <Modal
        title={getText('pharmacy-edit-department')}
        titleSize="sm"
        dismissButtonProps={{
          onPress: closeModal,
          logger: { id: 'pharmacy-general-info-form-cancel-button-modal' },
        }}
        cancelButtonProps={{
          onPress: closeModal,
          hierarchy: 'tertiary-gray',
          logger: { id: 'pharmacy-general-info-form-cancel-button-modal' },
        }}
        okButtonProps={{
          onPress: methods.handleSubmit(handleSubmit),
          logger: { id: 'pharmacy-general-info-form-ok-button-modal' },
          hierarchy: 'pharmacy-primary',
          text: getText('ok'),
        }}
        deleteButtonProps={
          editingDepartmentId
            ? {
                hierarchy: 'tertiary-gray',
                onPress: () => setShowCloseConfirmation(true),
                logger: { id: 'task-type-form-cancel-button-modal' },
                pinLeft: true,
                text: getText('delete'),
                icon: TrashIcon,
              }
            : undefined
        }
        show={showModalDepartment}
        isScrollable={true}
      >
        <Form methods={methods}>
          <Form.Row>
            <Form.Column style={styles.textWrapper}>
              <View style={styles.textView}>
                <Text style={styles.textFormat}>
                  {getText('pharmacy-company-name')}
                </Text>
              </View>
            </Form.Column>

            <Form.Column style={styles.inputWrapper}>
              <TextField
                style={styles.inputStyle}
                name="name"
                type="text"
                rules={{
                  maxLength: {
                    value: 16,
                    message: getText('pharmacy-brand-name-length'),
                  },
                }}
              />
            </Form.Column>
          </Form.Row>
        </Form>
      </Modal>

      <Modal
        title={getText('pharmacy-delete-department')}
        cancelButtonProps={{
          onPress: () => {
            closeModal;
            setShowCloseConfirmation(false);
          },
          logger: { id: 'confirmation-cancel-button' },
        }}
        okButtonProps={{
          onPress: handleDelete,
          logger: { id: 'confirmation-accept-button' },
          hierarchy: 'pharmacy-primary',
        }}
        show={showCloseConfirmation}
        size="sm"
      >
        <Text selectable>{getText('pharmacy-delete-department-info')}</Text>
      </Modal>
    </>
  );
};

const useStyles = makeStyles(() => ({
  inputWrapper: {
    display: 'flex',
    gap: 8,
    alignSelf: 'stretch',
    flex: 2,
  },
  inputStyle: {
    height: 44,
  },
  textWrapper: {
    flexDirection: 'column',
    flex: 1,
  },
  textView: {
    display: 'flex',
    alignItems: 'flex-start',
    paddingTop: 10,
    height: 44,
  },
  textFormat: {
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 24,
  },
}));
