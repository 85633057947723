import { IconButton } from 'assets/components/icon-button';
import { Modal } from 'assets/components/modal';
import { Text } from 'assets/components/text';
import { PencilIcon, TrashIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { logError } from 'assets/logging/logger';
import { makeStyles, useTheme } from 'assets/theme';
import { FunctionComponent, PropsWithChildren, useState } from 'react';
import { View } from 'react-native';
import { usePharmacySettingsState } from '../pharmacy-settings-store';

export const DepartmentItemRenderer: FunctionComponent<
  PropsWithChildren<DepartmentItemRendererProps>
> = ({ id, name }) => {
  const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);
  const { removeDepartment, setShowModalDepartment, getDepartmentsHours } =
    usePharmacySettingsState();
  const styles = useStyles();
  const theme = useTheme();

  const handleDelete = (id: string) => {
    removeDepartment(id)
      .then(() => getDepartmentsHours())
      .then(() => setShowCloseConfirmation(false))
      .catch((error: Error) => logError(error));
  };

  return (
    <>
      <View style={styles.viewStyle}>
        <View style={styles.textView}>
          <Text style={styles.textStyle}>{name}</Text>
        </View>
        <View style={styles.buttonView}>
          <IconButton
            icon={PencilIcon}
            onPress={() => setShowModalDepartment(true, id)}
            size={16}
            color={theme.palette.gray[500]}
            logger={{ id: 'task-type-renderer-action-button-edit' }}
          />
          <IconButton
            icon={TrashIcon}
            onPress={() => setShowCloseConfirmation(true)}
            size={16}
            color={theme.palette.gray[500]}
            logger={{ id: 'task-type-renderer-action-button-delete' }}
          />
        </View>
        <Modal
          title={getText('pharmacy-delete-department')}
          cancelButtonProps={{
            onPress: () => {
              setShowCloseConfirmation(false);
            },
            logger: { id: 'confirmation-cancel-button' },
          }}
          okButtonProps={{
            onPress: () => handleDelete(id),
            logger: { id: 'confirmation-accept-button' },
            hierarchy: 'pharmacy-primary',
          }}
          show={showCloseConfirmation}
          size="sm"
        >
          <Text selectable>{getText('pharmacy-delete-department-info')}</Text>
        </Modal>
      </View>
    </>
  );
};

export interface DepartmentItemRendererProps {
  id: string;
  name: string;
}

const useStyles = makeStyles((theme) => ({
  viewStyle: {
    flexDirection: 'row',
    gap: 15,
    alignItems: 'flex-start',
    borderBottomColor: theme.palette.gray[200],
    borderBottomWidth: 1,
    padding: theme.getSpacing(1),
    marginBottom: theme.getSpacing(1),
  },
  textView: {
    flex: 4,
    flexDirection: 'row',
  },
  buttonView: {
    width: 60,
    flexDirection: 'row',
  },
  textStyle: {
    ...theme.fonts.regular,
    fontSize: 14,
    lineHeight: 16,
    color: theme.palette.gray[900],
    fontWeight: '600',
  },
}));
