import { Modal } from 'assets/components/modal';
import { useForm } from 'assets/form';
import React, { FunctionComponent, PropsWithChildren, useEffect } from 'react';

import {
  ParentCompanyDto,
  UpdatePharmacyDto,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import * as validate from '@digitalpharmacist/validation-dp';
import { Text } from 'assets/components/text';
import { TextField } from 'assets/components/text-field';
import { Form } from 'assets/layout/form/Form';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import _isEqual from 'lodash/isEqual';
import { View } from 'react-native';
import { AdvancedDropDownField } from '../../../../components/advanced-dropdown';
import { usePharmacySettingsState } from '../pharmacy-settings-store';

export const GeneralInfoModal: FunctionComponent<
  PropsWithChildren<any>
> = () => {
  const {
    showModalGeneralInfo,
    pharmacy,
    allPMS,
    setShowModalGeneralInfo,
    setPharmacy,
    getAllPMS,
    getPharmacy,
  } = usePharmacySettingsState();
  const styles = useStyles();

  useEffect(() => {
    methods.reset({
      ...pharmacy,
      pms: { label: pharmacy?.pms, value: pharmacy?.pms },
    });
  }, [pharmacy]);

  useEffect(() => {
    getAllPMS();
  }, []);

  const methods = useForm<GeneralInfoFormProps>({
    defaultValues: {
      ...pharmacy,
      pms: { label: pharmacy?.pms, value: pharmacy?.pms },
    },
    mode: 'onChange',
  });

  const handleSubmit = async () => {
    const formValue = methods.getValues();
    const updatedPharmacy: UpdatePharmacyDto = {
      ...pharmacy,
      ...formValue,
      pms: formValue.pms?.value,
    };
    if (!_isEqual(updatedPharmacy, pharmacy)) {
      await setPharmacy(updatedPharmacy);
      await getPharmacy();
    }
    setShowModalGeneralInfo(false);
  };

  const closeModal = (updatePharmacy?: UpdatePharmacyDto) => {
    setShowModalGeneralInfo(false);
    const updateRecord = updatePharmacy || pharmacy;
    methods.reset({
      ...updateRecord,
      pms: { label: updateRecord?.pms, value: updateRecord?.pms },
    });
  };

  const optionsPMS: SelectionParent[] = [
    ...allPMS.map((pms) => ({
      label: pms.name,
      value: pms.name,
    })),
  ];

  return (
    <Modal
      title={getText('pharmacy-edit-general-info')}
      titleSize="sm"
      dismissButtonProps={{
        onPress: closeModal,
        logger: { id: 'pharmacy-general-info-form-cancel-button-modal' },
      }}
      cancelButtonProps={{
        onPress: closeModal,
        hierarchy: 'tertiary-gray',
        logger: { id: 'pharmacy-general-info-form-cancel-button-modal' },
      }}
      okButtonProps={{
        onPress: methods.handleSubmit(handleSubmit),
        logger: { id: 'pharmacy-general-info-form-ok-button-modal' },
        hierarchy: 'pharmacy-primary',
        text: getText('ok'),
      }}
      show={showModalGeneralInfo}
      isScrollable={true}
    >
      <Form methods={methods}>
        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>
                {getText('pharmacy-company-name')}
              </Text>
            </View>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            <TextField
              style={styles.inputStyle}
              name="name"
              type="text"
              rules={{
                maxLength: {
                  value: 16,
                  message: getText('pharmacy-brand-name-length'),
                },
              }}
            />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>
                {getText('pharmacy-brand-full-name')}
              </Text>
            </View>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            <TextField style={styles.inputStyle} name="full_name" type="text" />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>
                {getText('pharmacy-brand-admin')}
              </Text>
            </View>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            <TextField style={styles.inputStyle} name="admin" type="text" />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>
                {getText('pharmacy-parent-company')}
              </Text>
            </View>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            <TextField
              style={styles.inputStyle}
              name="parent_company.name"
              type="text"
              disabled
            />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>{getText('pharmacy-ncpdp')}</Text>
            </View>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            <TextField style={styles.inputStyle} name="ncpdp_id" type="text" />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>{getText('pharmacy-npi')}</Text>
            </View>
          </Form.Column>
          <Form.Column style={styles.inputWrapper}>
            <TextField style={styles.inputStyle} name="npi" type="text" />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>{getText('pharmacy-pms')}</Text>
            </View>
          </Form.Column>
          <Form.Column style={styles.inputWrapper}>
            <AdvancedDropDownField
              options={optionsPMS}
              name="pms"
              getOptionValue={(option) => option.value}
              getOptionLabel={(option) => option.label}
              isSearchable={true}
            />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>
                {getText('pharmacy-facebook')}
              </Text>
            </View>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            <TextField
              style={styles.inputStyle}
              name="facebook_url"
              type="text"
              rules={{
                validate: (value: string) => {
                  return validate.isURL(value)
                    ? true
                    : getText('url-is-not-valid');
                },
              }}
            />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>
                {getText('pharmacy-instagram')}
              </Text>
            </View>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            <TextField
              style={styles.inputStyle}
              name="instagram_handle"
              type="text"
              rules={{
                validate: (value: string) => {
                  return validate.isURL(value)
                    ? true
                    : getText('url-is-not-valid');
                },
              }}
            />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>
                {getText('pharmacy-twitter')}
              </Text>
            </View>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            <TextField
              style={styles.inputStyle}
              name="twitter_handle"
              type="text"
              rules={{
                validate: (value: string) => {
                  return validate.isURL(value)
                    ? true
                    : getText('url-is-not-valid');
                },
              }}
            />
          </Form.Column>
        </Form.Row>
      </Form>
    </Modal>
  );
};

export interface SelectionParent {
  value: string;
  label: string;
}

interface GeneralInfoFormProps {
  name: string;
  full_name?: string;
  admin?: string;
  parent_company?: ParentCompanyDto;
  ncpdp_id?: string;
  npi?: string;
  pms?: SelectionParent;
  facebook_url?: string;
  instagram_handle?: string;
  twitter_handle?: string;
}

const useStyles = makeStyles(() => ({
  inputWrapper: {
    display: 'flex',
    gap: 8,
    alignSelf: 'stretch',
    flex: 2,
  },
  inputStyle: {
    height: 44,
  },
  textWrapper: {
    flexDirection: 'column',
    flex: 1,
  },
  textView: {
    display: 'flex',
    alignItems: 'flex-start',
    paddingTop: 10,
    height: 44,
  },
  textFormat: {
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 24,
  },
}));
