import { AppointmentTypeSlotDto } from '@digitalpharmacist/appointment-service-client-axios';
import React, { FunctionComponent, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { View } from 'react-native';
import { useFormContext } from 'react-hook-form';
import {
  convertDateTimeFromUtcToPharmacyTimezone,
  DEFAULT_PHARMACY_TIMEZONE,
  formatDate,
} from '../../common/datetime-utils';
import { makeStyles } from 'assets/theme';
import { TouchableOpacity } from 'react-native';
import { Text } from 'assets/components/text';

export const AppointmentSlotPicker: FunctionComponent<
  AppointmentSlotPickerProps
> = ({ slots, name, onSlotChange }) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedDateString, setSelectedDateString] = useState<string>('');
  const [selectedTime, setSelectedTime] = useState<string>();
  const dates = Object.keys(slots).map((date) => {
    // JavaScript's Date creates a new date in user's timezone and there is no way to override it.
    // Here we're adding the timezone offset to the initial date to compensate it.
    const initialDate = new Date(date);
    const timezoneOffset = initialDate.getTimezoneOffset();
    const dateOffset = new Date(
      initialDate.getTime() + timezoneOffset * 60 * 1000,
    );

    return dateOffset;
  });
  const times = slots[selectedDateString];
  const formContext = useFormContext();
  const styles = useStyles();

  if (!formContext) {
    throw new Error('Date Time Picker must have a parent form context');
  }

  useEffect(() => {
    if (formContext.getValues('time')) {
      onDateChange(new Date(formContext.getValues('time')));
      onTimeChange(formContext.getValues('time'));
    }
  }, []);

  const onDateChange = (date: Date | null) => {
    const dateString = date ? formatDate(date.toISOString(), 'YYYY-MM-DD') : '';
    setSelectedDate(date);
    setSelectedDateString(dateString);
    setSelectedTime('');
    formContext.setValue(name, '');
    onSlotChange?.('');
  };

  const onTimeChange = (time: string) => {
    setSelectedTime(time);
    formContext.setValue(name, time);

    onSlotChange?.(time);
  };

  const getSortedTimes = (timesToSort: AppointmentTypeSlotDto[]) => {
    return timesToSort.sort(
      (dateA, dateB) =>
        new Date(dateA.time).getTime() - new Date(dateB.time).getTime(),
    );
  };

  return (
    <View style={styles.container}>
      <View>
        <DatePicker
          selected={selectedDate ? selectedDate : ('' as unknown as null)} // workaround to have no initial value in the date picker
          onChange={onDateChange}
          inline
          wrapperClassName="datepicker--date"
          includeDates={dates}
        />
      </View>
      <View style={styles.timeContainer}>
        {times &&
          getSortedTimes(times).map((time) => (
            <TouchableOpacity
              onPress={() => onTimeChange(time.time)}
              key={time.time}
            >
              <View
                style={[
                  styles.timeButton,
                  selectedTime === time.time && styles.timeButtonSelected,
                ]}
              >
                <Text
                  style={
                    selectedTime === time.time && styles.timeButtonTextSelected
                  }
                >
                  {convertDateTimeFromUtcToPharmacyTimezone(
                    time.time,
                    DEFAULT_PHARMACY_TIMEZONE,
                    'hh:mma',
                  )}
                </Text>
              </View>
            </TouchableOpacity>
          ))}
      </View>
    </View>
  );
};

export type AppointmentSlots = Record<string, AppointmentTypeSlotDto[]>;

export interface AppointmentSlotPickerProps {
  slots: AppointmentSlots;
  name: string;
  onSlotChange?: (time: string) => void;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    alignSelf: 'flex-start',
  },
  timeContainer: {
    width: 150,
    marginLeft: theme.getSpacing(2),
    paddingRight: theme.getSpacing(2),
    position: 'absolute',
    right: -theme.getSpacing(2),
    top: 0,
    bottom: 0,
    transform: [{ translateX: '100%' as any as number }],
    overflowY: 'auto',
  },
  timeButton: {
    borderWidth: 1,
    borderColor: theme.palette.gray[300],
    borderRadius: theme.roundness,
    paddingVertical: theme.getSpacing(1),
    paddingHorizontal: theme.getSpacing(2),
    fontSize: 18,
    marginBottom: theme.getSpacing(1),
    alignItems: 'center',
  },
  timeButtonSelected: {
    backgroundColor: theme.palette.primary[600],
    borderColor: theme.palette.primary[600],
  },
  timeButtonTextSelected: {
    color: theme.palette.white,
  },
}));
