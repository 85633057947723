import { ParentCompanyDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import { Icon } from 'assets/components/icon';
import { Text } from 'assets/components/text';
import { InfoIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import { FunctionComponent, PropsWithChildren } from 'react';
import { View } from 'react-native';
import { TooltipWrapper } from 'react-tooltip';
import { RowItemRender } from '../../../../components/Settings/RowItemRender';
import { Tooltip } from '../../../../components/Tooltip';

export const GeneralInfoItemRenderer: FunctionComponent<
  PropsWithChildren<GeneralInfoItemRendererProps>
> = ({
  name,
  full_name,
  admin,
  parent_company,
  ncpdp_id,
  npi,
  pms,
  facebook_url,
  instagram_handle,
  twitter_handle,
}) => {
  const styles = useStyles();
  return (
    <>
      <RowItemRender
        title={getText('pharmacy-brand-full-name')}
        value={full_name ?? ''}
      />

      <View style={styles.container}>
        <View style={styles.firstColumnContent}>
          <Text style={styles.title}>{getText('pharmacy-company-name')}</Text>
          <View style={styles.tooltip}>
            <TooltipWrapper tooltipId="pharmacy-general-info-limited-brand-name">
              <Icon icon={InfoIcon} size={16} />
            </TooltipWrapper>
            <Tooltip
              id="pharmacy-general-info-limited-brand-name"
              place="top"
              variant="light"
              text={getText('pharmacy-general-info-limited-brand-name')}
            />
          </View>
        </View>

        <View style={{ flex: 4 }}>
          <Text style={styles.value}>{name}</Text>
        </View>
      </View>

      <RowItemRender
        title={getText('pharmacy-parent-company')}
        value={parent_company?.name ?? ''}
      />
      <RowItemRender title={getText('pharmacy-ncpdp')} value={ncpdp_id ?? ''} />
      <RowItemRender title={getText('pharmacy-npi')} value={npi ?? ''} />
      <RowItemRender title={getText('pharmacy-pms')} value={pms ?? ''} />
      <RowItemRender
        title={getText('pharmacy-facebook')}
        value={facebook_url ?? ''}
      />
      <RowItemRender
        title={getText('pharmacy-instagram')}
        value={instagram_handle ?? ''}
      />
      <RowItemRender
        title={getText('pharmacy-twitter')}
        value={twitter_handle ?? ''}
      />
    </>
  );
};

interface GeneralInfoItemRendererProps {
  name: string;
  full_name?: string;
  admin?: string;
  parent_company?: ParentCompanyDto;
  ncpdp_id?: string;
  npi?: string;
  pms?: string;
  facebook_url?: string;
  instagram_handle?: string;
  twitter_handle?: string;
}

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: 'row',
    gap: 15,
    alignItems: 'flex-start',
    borderBottomColor: theme.palette.gray[200],
    borderBottomWidth: 1,
    padding: theme.getSpacing(1),
    marginBottom: theme.getSpacing(1),
  },
  firstColumnContent: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    ...theme.fonts.regular,
    fontSize: 16,
    lineHeight: 24,
    color: theme.palette.gray[900],
    fontWeight: '600',
  },
  value: {
    ...theme.fonts.regular,
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.gray[700],
  },
  tooltip: {
    marginLeft: theme.getSpacing(1),
    marginTop: theme.getSpacing(0.5),
  },
}));
